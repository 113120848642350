import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledInput,
    ControlledNumberInput,
    DialogTitleWithClose,
    IAuction,
    ILot,
    ILotForm,
    InUseWarning,
} from '../../../shared';
import { useSaveLot } from '../../hooks';
import { lotFromFormMapper, lotToFormMapper } from '../../mappers';
import { useLotSchema } from '../../validators';
import { SelectMultipleArticles } from '../select-multiple-articles/select-multiple-articles.component';

interface Props {
    open: boolean;
    close: () => void;
    auction: IAuction;
    lot: ILot | undefined;
}

export const SaveLotDialog: FC<Props> = ({ open, close, auction, lot }) => {
    const { t } = useTranslation();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);

    const { mutateAsync: saveLot, isPending: isSaving } = useSaveLot();

    const form = useForm<ILotForm>({
        resolver: yupResolver(useLotSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (open) {
            lot ? form.reset(lotToFormMapper(lot)) : form.reset({ articles: [{ article: null }] });
        }
    }, [form, open, lot]);

    const onSubmit = async (item: ILotForm) => {
        try {
            await saveLot({ id: lot?.id, item: lotFromFormMapper(item, auction.id) });
            close();
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {lot ? `${t('editLot')} ${lot.lotNumber}` : t('newLot')}
                    </DialogTitleWithClose>

                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <Stack direction="row" spacing={2} pl={2}>
                                <ControlledNumberInput
                                    name="lotNumber"
                                    label={t('lotNumber')}
                                    required
                                    decimalScale={0}
                                />
                                <ControlledInput name="lotNumberSuffix" label={t('lotNumberSuffix')} />
                            </Stack>

                            <SelectMultipleArticles lot={lot} auctionId={auction.id} />

                            <Stack direction="row" spacing={2} pl={2}>
                                <ControlledNumberInput
                                    name="minEstimate"
                                    label={t('minEstimate')}
                                    InputProps={{ startAdornment: '€ ' }}
                                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                                />
                                <ControlledNumberInput
                                    name="maxEstimate"
                                    label={t('maxEstimate')}
                                    InputProps={{ startAdornment: '€ ' }}
                                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                                />
                            </Stack>

                            <Stack direction="row" spacing={2} pl={2}>
                                <ControlledInput
                                    name="descriptionNl"
                                    label={t('descriptionNl')}
                                    required
                                    multiline
                                    rows={4}
                                />
                                <ControlledInput
                                    name="descriptionEn"
                                    label={t('descriptionEn')}
                                    required
                                    multiline
                                    rows={4}
                                />
                            </Stack>
                        </Stack>

                        <InUseWarning
                            show={showUniqueWarning}
                            onClose={() => setShowUniqueWarning(false)}
                            text={t('uniqueLotNumberWarning')}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
