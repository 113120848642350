import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, FormGrid, Section } from '../../../shared';

export const ArticleEstimateInputs: FC = () => {
    const { t } = useTranslation();
    return (
        <Section title={t('estimate')}>
            <FormGrid xs={12} lg={6}>
                <ControlledNumberInput
                    name="minEstimate"
                    label={t('minEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 } }}
                />
                <ControlledNumberInput
                    name="maxEstimate"
                    label={t('maxEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 } }}
                />
            </FormGrid>
            <ControlledInput
                name="estimateComment"
                label={t('estimateComment')}
                multiline
                rows={4}
                sx={{ pl: 2, '.MuiFormLabel-root': { ml: 2 } }}
            />
        </Section>
    );
};
