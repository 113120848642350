import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    InUseWarning,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useAbbreviationsList, useDeleteAbbreviation } from '../../hooks';

export const AbbreviationsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.ABBREVIATIONS_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { data: abbreviations, isPending } = useAbbreviationsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutateAsync: deleteAbbreviation } = useDeleteAbbreviation();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteAbbreviation(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteAbbreviation],
    );

    const columns: GridColDef[] = [
        { field: 'code', headerName: t('code'), minWidth: 150, flex: 1 },
        { field: 'descriptionNl', headerName: t('descriptionNl'), minWidth: 150, flex: 1, sortable: false },
        {
            field: 'descriptionEn',
            headerName: t('descriptionEn'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/abbreviations/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('abbreviationDeleteWarningTitle')}
                            deleteWarningText={t('abbreviationDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('abbreviations')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/abbreviations/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-abbreviation"
                    >
                        {t('newAbbreviation')}
                    </Button>
                ),
            ]}
        >
            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('abbreviationHasArticles')}
            />

            <TableComponent
                rows={abbreviations?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={abbreviations?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/abbreviations/${row.id}/edit`)}
            />
        </Page>
    );
};
