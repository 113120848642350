import { useMemo } from 'react';
import { array, boolean, mixed, object, string } from 'yup';
import { ContactType, IContact, ICustomerAddressForm, ILocationInvite } from '../../shared';
import { useTranslation } from 'react-i18next';

export function useCustomerSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                address: object<ICustomerAddressForm>()
                    .shape({
                        street: string().required(),
                        number: string().required(),
                        postalCode: string().required(),
                        city: string().required(),
                        country: object({ code: string().required(), label: string().required() })
                            .transform((value) => (value ? value : null))
                            .required(),
                    })
                    .required(),
                invites: array()
                    .of(
                        object<ILocationInvite>().shape({
                            location: string().required(),
                            wantsInvite: boolean().required(),
                        }),
                    )
                    .optional(),
                contactDetails: array().of(
                    object<IContact>().shape({
                        id: string().optional(),
                        type: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
                        data: string()
                            .required()
                            .when('type', {
                                is: ContactType.Email,
                                then: (schema) => schema.matches(/((.+)(@{1})(.+)(\.{1})(.+))/, t('invalidEmail')),
                            }),
                        comment: string().optional(),
                    }),
                ),
                comment: string().optional(),
                accountNumber: string().optional(),
                taxNumber: string().optional(),
                companyName: string().optional(),
            }),
        [t],
    );
}
