import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './translations';
import { IEnvironment } from './shared';
import { App } from './app.component';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const initializeApp = async () => {
    const { data: environment } = await axios.get<IEnvironment>(`/environment`);

    window.auction = {
        environment,
    };

    if (environment.sentryEnabled) {
        Sentry.init({
            dsn: environment.sentryUrl,
            integrations: [browserTracingIntegration()],
            environment: environment.environment,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
        });
    }
};
initializeApp().then(() => {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
});
