import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledNumberInput, DialogTitleWithClose, IBuyer, IBuyerForm, InUseWarning } from '../../../shared';
import { useSaveBuyer } from '../../hooks';
import { buyerFromFormMapper } from '../../mappers';
import { useBuyerSchema } from '../../validators';
import { SelectCustomer } from '../select-customer/select-customer.component';

interface Props {
    open: boolean;
    close: () => void;
    auctionId: string;
    buyer: IBuyer | undefined;
}

export const SaveBuyerDialog: FC<Props> = ({ open, close, auctionId, buyer }) => {
    const { t } = useTranslation();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);

    const { mutateAsync: saveBuyer, isPending: isSaving } = useSaveBuyer();

    const form = useForm<IBuyerForm>({
        resolver: yupResolver(useBuyerSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (open) {
            buyer ? form.reset(buyer) : form.reset({ buyerNumber: undefined });
        }
    }, [form, open, buyer]);

    const onSubmit = async (item: IBuyerForm) => {
        try {
            await saveBuyer({ id: buyer?.id, item: buyerFromFormMapper(item, auctionId) });
            close();
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit, (er) => console.log(er))} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {buyer ? t('editBuyer') : t('newBuyer')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <ControlledNumberInput
                                name="buyerNumber"
                                label={t('buyerNumber')}
                                required
                                decimalScale={0}
                            />
                            <SelectCustomer />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
            <InUseWarning
                show={showUniqueWarning}
                onClose={() => setShowUniqueWarning(false)}
                text={t('uniqueBuyerNumberWarning')}
            />
        </Dialog>
    );
};
