import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledDatePicker,
    ControlledInput,
    DialogTitleWithClose,
    IConsignment,
    IConsignmentForm,
    ISupplier,
} from '../../../shared';
import { useSaveConsignment } from '../../hooks';
import { consignmentFromFormMapper, consignmentToFormMapper } from '../../mappers';
import { useConsignmentSchema } from '../../validators';
import { SelectAutocompleteSupplier } from './select-autocomplete-supplier.component';

interface Props {
    open: boolean;
    close: () => void;
    consignment?: IConsignment;
    supplier?: ISupplier;
}

export const SaveConsignmentDialog: FC<Props> = ({ open, close, consignment, supplier }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: saveConsignment, isPending: isSaving } = useSaveConsignment();

    const form = useForm<IConsignmentForm>({
        resolver: yupResolver(useConsignmentSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (open) {
            consignment ? form.reset(consignmentToFormMapper(consignment)) : form.reset({ supplier: supplier });
        }
    }, [supplier, form, open, consignment]);

    const onSubmit = async (item: IConsignmentForm) => {
        if (consignment) {
            await saveConsignment({ id: consignment.id, item: consignmentFromFormMapper(item) });
            close();
        } else {
            const { id } = await saveConsignment({ item: consignmentFromFormMapper(item) });
            close();
            navigate(`/article/consignments/${id}`);
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {consignment ? t('editConsignment') : t('newConsignment')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <Stack direction="row" alignItems="center">
                                <ControlledInput
                                    name="consignmentNumberPart1"
                                    label={t('consignmentNumber')}
                                    required
                                    sx={{ minWidth: 100 }}
                                />
                                <Typography sx={{ p: 2 }}>/</Typography>
                                <ControlledInput name="consignmentNumberPart2" required sx={{ minWidth: 100 }} />
                            </Stack>
                            <ControlledDatePicker name="date" label={t('date')} />
                            {!supplier && <SelectAutocompleteSupplier />}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
