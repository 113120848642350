import { FormControl, FormHelperText, InputLabel, OutlinedInput, Select } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label?: string;
    items?: string[];
};

export const ControlledSelect: FC<Props> = ({ name, required, label, children, items = [], ...selectProps }) => {
    const handleOnChangeMultipleSelect = (e: any) => {
        if (e.target.value.includes('all')) {
            if (e.target.value.length === items.length + 1) {
                e.target.value = [];
            } else {
                e.target.value = items;
            }
        }
        return e;
    };
    return (
        <Controller
            name={name}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} sx={{ minWidth: 300, ...selectProps.sx }}>
                    {label && (
                        <InputLabel id={name} required={required}>
                            {label}
                        </InputLabel>
                    )}
                    <Select
                        onChange={!selectProps.multiple ? onChange : (e) => onChange(handleOnChangeMultipleSelect(e))}
                        value={value}
                        id={`${name}-select`}
                        required={required}
                        input={<OutlinedInput notched label={label} />}
                        {...selectProps}
                        sx={{ minWidth: 300, ...selectProps.sx }}
                    >
                        {children}
                    </Select>
                    {error && (
                        <FormHelperText id={`${name}-field-helper-text`}>{error ? error.message : null}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
