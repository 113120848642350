import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGrid, IArticle, LabelValue, Widget } from '../../../shared';

interface Props {
    article: IArticle;
}

export const ArticleDetailDescription: FC<Props> = ({ article }) => {
    const { t } = useTranslation();
    const categories = article?.categories.map((category) => category.descriptionNl).join(', ');

    return (
        <Widget title={t('description')} small>
            <LabelValue
                label={t('artist')}
                value={
                    article.artist ? (
                        <a href={`/admin/artists/${article.artist?.id}/edit`}>
                            {`${article.artist?.lastname} ${article.artist?.firstname}`}
                        </a>
                    ) : (
                        '-'
                    )
                }
            />
            <LabelValue label={t('categories')} value={categories} />
            <LabelValue label={t('descriptionNl')} value={article.descriptionNl} sx={{ whiteSpace: 'pre-line' }} />
            <LabelValue label={t('descriptionEn')} value={article.descriptionEn} sx={{ whiteSpace: 'pre-line' }} />
            <FormGrid xs={3}>
                <LabelValue
                    label={t('width')}
                    value={article.dimensions?.widthInCm ? `${article.dimensions.widthInCm} cm` : ''}
                />
                <LabelValue
                    label={t('height')}
                    value={article.dimensions?.heightInCm ? `${article.dimensions.heightInCm} cm` : ''}
                />
                <LabelValue
                    label={t('depth')}
                    value={article.dimensions?.depthInCm ? `${article.dimensions.depthInCm} cm` : ''}
                />
                <LabelValue
                    label={t('weight')}
                    value={
                        article.dimensions?.weight
                            ? `${article.dimensions?.weight} ${article.dimensions?.weightUnit}`
                            : ''
                    }
                />
            </FormGrid>
        </Widget>
    );
};
