import { IAbbreviation, IArtist } from '../../shared';
import { formatArtistInformation } from './format-artist.util';

export function descriptionReplacementUtil(
    artist: IArtist,
    input: string,
    language: 'nl' | 'en',
    abbreviationsMap: Record<string, IAbbreviation>,
) {
    const description: string[] = [];
    const artistInformation = formatArtistInformation(artist).toUpperCase();
    const inputAbbreviations = input?.replaceAll('\n', ' \n ').split(' ') || [];

    inputAbbreviations.forEach((word) => {
        const lastChar = /[.,!?:]$/.test(word) ? word.charAt(word.length - 1) : '';
        const abbreviation = abbreviationsMap[lastChar.length > 0 ? word.substring(0, word.length - 1) : word];

        if (abbreviation) {
            description.push(
                (abbreviation?.[language === 'nl' ? 'descriptionNl' : 'descriptionEn'] || '').concat(lastChar),
            );
        } else {
            description.push(word);
        }
    });

    return `${artistInformation}${description.join(' ').replaceAll(' \n ', '\n')}`;
}
