import { Search } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    addressFormatter,
    IAddress,
    InUseWarning,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useDebouncedState,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useDeleteSupplier, useSuppliersList } from '../../hooks';
import { SupplierFilter } from '../../components';

export const SuppliersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPLIERS_WRITE);

    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [debouncedPostalCode, , setPostalCode] = useDebouncedState('');
    const [debouncedCity, , setCity] = useDebouncedState('');

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'sequenceNumber', sort: 'asc' as GridSortDirection }],
    });

    const { data: suppliers, isPending } = useSuppliersList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        postalCode: debouncedPostalCode,
        city: debouncedCity,
    });

    const { mutateAsync: deleteSupplier } = useDeleteSupplier();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteSupplier(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteSupplier],
    );

    const columns: GridColDef[] = [
        { field: 'sequenceNumber', headerName: t('sequenceNumber'), minWidth: 100, flex: 0.5 },
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        { field: 'initials', headerName: t('initials'), minWidth: 100, flex: 0.5, sortable: false },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: IAddress) => addressFormatter(value),
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${id}/detail`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/article/suppliers/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('supplierDeleteWarningTitle')}
                            deleteWarningText={t('supplierDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('suppliers')}
            filter={
                <SupplierFilter setPostalCode={(value) => setPostalCode(value)} setCity={(value) => setCity(value)} />
            }
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/article/suppliers/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-supplier"
                    >
                        {t('newSupplier')}
                    </Button>
                ),
            ]}
        >
            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('supplierHasConsignments')}
            />

            <TableComponent
                rows={suppliers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={suppliers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/article/suppliers/${row.id}/detail`)}
            />
        </Page>
    );
};
