import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    InUseWarning,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useCategoriesList, useDeleteCategory } from '../../hooks';

export const CategoriesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CATEGORIES_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { data: categories, isPending } = useCategoriesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutateAsync: deleteCategory } = useDeleteCategory();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteCategory(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteCategory],
    );

    const columns: GridColDef[] = [
        { field: 'code', headerName: t('code'), minWidth: 150, flex: 1 },
        { field: 'descriptionNl', headerName: t('descriptionNl'), minWidth: 150, flex: 1, sortable: false },
        {
            field: 'descriptionEn',
            headerName: t('descriptionEn'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/categories/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('categoryDeleteWarningTitle')}
                            deleteWarningText={t('categoryDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('categories')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/categories/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-category"
                    >
                        {t('newCategory')}
                    </Button>
                ),
            ]}
        >
            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('categoryHasArticles')}
            />

            <TableComponent
                rows={categories?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={categories?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/categories/${row.id}/edit`)}
            />
        </Page>
    );
};
