export default {
    auction: 'Veiling',
    auctions: 'Veilingen',
    newAuction: 'Nieuwe veiling',
    editAuction: 'Wijzig veiling',
    auctionNumber: 'Veilingnummer',
    location: 'Locatie',
    auctionDeleteWarningTitle: 'Veiling verwijderen',
    auctionDeleteWarningText: 'Weet u zeker dat u deze veiling wilt verwijderen?',
    auctionHasLots: 'Deze veiling bevat loten en kan daardoor niet verwijderd worden',
    getInternalCatalog: 'Interne catalogus',
    getPublicCatalog: 'Publieke catalogus',
    getArtistCatalog: 'Artiesten',

    lots: 'Loten',
    lot: 'Lot',
    newLot: 'Nieuw lot',
    editLot: 'Wijzig lot',
    lotNumber: 'Lotnummer',
    lotNumberSuffix: 'Suffix lotnummer',
    invalidLotNumberSuffix: 'Cijfers zijn niet toegestaan',
    maxSuffixLength: 'Mag niet bestaan uit meerdere karakters',
    lotStatus: 'Lot status',
    AWAITING_AUCTION: 'In afwachting van veiling',
    OFFERED_IN_AUCTION: 'Aangeboden in veiling',
    SOLD: 'Verkocht',
    NOT_SOLD: 'Niet verkocht',
    PAID: 'Betaald',
    NOT_PAID: 'Niet betaald',
    lotDeleteWarningTitle: 'Lot verwijderen',
    lotDeleteWarningText: 'Weet u zeker dat u dit lot wilt verwijderen?',
    uniqueLotNumberWarning: 'Dit lot nummer is al reeds in gebruik binnen deze veiling.',
    startSearchingArticle: 'Zoek een artikel',
    noArticleFound: 'Geen artikel gevonden',
    noArticleSelected: 'Selecteeer minstens één artikel',
    lotUpdatedDate: 'Datum laatste wijziging',
    noLotHistory: 'Artikel is nog niet toegewezen aan een lot',

    articleUsedWithinLot: 'Dit artikel is reeds toegekend aan lot: {{linkedLots}} ',
    articleUsedWithinLots: 'Dit artikel is reeds toegekend aan loten: {{linkedLots}} ',

    articleUsedWithinLotInOtherAuction:
        'Het geselecteerde artikel werd reeds aan een lot toegekend binnen een andere veiling. Ben je zeker dat je het artikel wilt toevoegen aan dit lot?',
    registerPayments: 'Registreer betalingen',

    auctionSlot: 'Veiling moment',
    auctionSlots: 'Veiling momenten',
    newAuctionSlot: 'Nieuw veiling moment',
    editAuctionSlot: 'Wijzig veiling moment',
    startTime: 'Start tijd',
    startDate: 'Start datum',
    endTime: 'Eind tijd',
    endDate: 'Eind datum',
    lotRange: 'Loten reeks',
    firstLotNumber: 'Eerste lot',
    lastLotNumber: 'Laatste lot',
    description: 'Beschrijving',
    auctionSlotDeleteWarningTitle: 'Veiling moment verwijderen',
    auctionSlotDeleteWarningText: 'Weet u zeker dat u deze veiling moment wilt verwijderen?',
    endTimeBeforeStartTime: 'Eind tijdstip moet na begin tijdstip vallen',
    lastLotNumberBeforeFirstLotNumber: 'Laatste lotnummer moet groter zijn dan eerste lotnummer',
    overlappingLotRange: 'De lotreeks overlapt met een ander veilingmoment',
    missingAuctionLots: 'Ontbrekende lotnummers',
    unassignedAuctionLots: 'Lotnummers zonder veilingmoment',
    showMissingAuctionLots: 'Toon ontbrekende lotnummers',
    addArticle: 'Voeg artikel toe',

    customers: 'Klanten',
    customer: 'Klant',
    customerNumber: 'Klantnummer',
    newCustomer: 'Nieuwe klant',
    customerDeleteWarningTitle: 'Klant verwijderen',
    customerDeleteWarningText: 'Weet u zeker dat u deze klant wilt verwijderen?',
    taxNumber: 'BTW nummer',
    companyName: 'Bedrijfsnaam',
    locationInvites: 'Locatie uitnodigingen',
    wantsInvite: 'Wilt uitnodigingen ontvangen voor <0>{{location}}</0>',
    wantsInvites: 'Wilt uitnodigingen ontvangen voor volgende locaties',
    assets: 'Bijlagen',
    deleteAssetTitle: 'Bijlage verwijderen',
    deleteAssetDescription: 'Ben je zeker dat je deze bijlage wilt verwijderen?',
    uploadFile: 'Sleep je bestand hierheen, of klik om een bestand te selecteren.',
    uploadFailed: {
        GENERIC_ERROR: 'Er ging iets mis bij het uploaden van het bestand.',
        FILE_TOO_BIG: 'Het bestand mag niet groter zijn dan {{max}}MB. Probeer opnieuw met een kleiner bestand.',
        WRONG_FILE_TYPE: 'Het bestand heeft een type dat niet toegestaan is. De toegelaten types zijn {{types}}.',
        FILE_TOO_BIG_AND_WRONG_FILE_TYPE: `Het bestand mag niet groter zijn dan {{max}}MB en is niet van een toegelaten type. Probeer opnieuw met een kleiner bestand. De toegelaten types zijn {{types}}.`,
    },

    buyers: 'Kopers',
    newBuyer: 'Nieuwe koper',
    editBuyer: 'Wijzig koper',
    buyerNumber: 'Kopernummer',
    uniqueBuyerNumberWarning: 'Dit koper nummer is al reeds in gebruik binnen deze veiling.',
    startSearchingCustomers: 'Zoek een klant',
    noCustomerFound: 'Geen klant gevonden',
    buyerDeleteWarningTitle: 'Koper verwijderen',
    buyerDeleteWarningText: 'Weet u zeker dat u deze koper wilt verwijderen?',

    start: 'Start',
    sold: 'Verkocht',
    notSold: 'Niet verkocht',
    hammerPrice: 'Hamerprijs',
    finishAuction: 'Voltooi veilingmoment',
    printAndNext: 'Druk af en volgende',
    printAndToOverview: 'Druk af en naar overzicht',
    toOverview: 'Naar overzicht',

    signatures: 'Handtekeningen',
    signed: 'Getekend',
    clear: 'Wis',
    resign: 'Herteken',
    noLotsWaitingForSignature: 'Er zijn momenteel geen loten meer die wachten op een handtekening',
};
