import { AddCircle, People } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    addressFormatter,
    ContactType,
    IBuyer,
    IContact,
    ICustomer,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useBuyersList, useDeleteBuyer } from '../../hooks';
import { SaveBuyerDialog } from '../save-buyer-dialog/save.buyer-dialog.component';

interface Props {
    auctionId: string;
}

export const BuyerData: FC<Props> = ({ auctionId }) => {
    const { t } = useTranslation();
    const [showBuyerModal, setShowBuyerModal] = useState<{ show: boolean; buyer: IBuyer | undefined }>({
        show: false,
        buyer: undefined,
    });

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'buyerNumber', sort: 'asc' as GridSortDirection }],
    });

    const { mutateAsync: deleteBuyer } = useDeleteBuyer();
    const { data: buyers, isPending } = useBuyersList({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        auctionId,
    });

    const columns: GridColDef[] = [
        { field: 'buyerNumber', headerName: t('buyerNumber'), minWidth: 200, flex: 0.5 },
        {
            field: 'customer',
            headerName: t('name'),
            minWidth: 200,
            flex: 0.75,
            valueFormatter: (value: ICustomer) => value.name,
        },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value, row) => addressFormatter(row.customer?.address),
            sortable: false,
        },
        {
            field: 'contactDetails.phone',
            headerName: t('phone'),
            minWidth: 200,
            flex: 0.75,
            valueFormatter: (value, row) =>
                row.customer?.contactDetails?.find((x: IContact) => x.type === ContactType.Phone)?.data,
            sortable: false,
        },
        {
            field: 'contactDetails.email',
            headerName: t('email'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value, row) =>
                row.customer?.contactDetails?.find((x: IContact) => x.type === ContactType.Email)?.data,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 100,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => setShowBuyerModal({ show: true, buyer: row })}
                    onDelete={() => deleteBuyer(row.id)}
                    deleteWarningTitle={t('buyerDeleteWarningTitle')}
                    deleteWarningText={t('buyerDeleteWarningText')}
                />
            ),
        },
    ];

    return (
        <Widget
            title={t('buyers')}
            icon={<People />}
            hasTable
            actions={
                <Stack direction="row" spacing={1}>
                    <SearchField search={search} onSearch={setSearch} />
                    <IconButton onClick={() => setShowBuyerModal({ show: true, buyer: undefined })}>
                        <AddCircle color="primary" />
                    </IconButton>
                </Stack>
            }
        >
            <TableComponent
                rows={buyers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={buyers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
            />
            <SaveBuyerDialog
                open={showBuyerModal.show}
                close={() => setShowBuyerModal({ show: false, buyer: undefined })}
                auctionId={auctionId}
                buyer={showBuyerModal.buyer}
            />
        </Widget>
    );
};
