import { IArtist } from '../../shared';

export function formatArtistInformation(artist?: IArtist) {
    return artist
        ? `${artist.lastname.toUpperCase()} ${artist.firstname.toUpperCase()}  ${
              artist.yearOfBirth || artist.yearOfDeath
                  ? `(${artist.yearOfBirth || '?'} - ${artist.yearOfDeath || '?'})`
                  : ''
          } ${artist.nationality ? `(${artist.nationality.toUpperCase()})` : ''}\n`
        : '';
}
