import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    ContactDataInputs,
    ContactType,
    ControlledAutocomplete,
    ControlledCheckbox,
    ControlledInput,
    FormGrid,
    Section,
    useCountries,
} from '../../../shared';
import React, { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { useLocationList } from '../../../admin/hooks';

export const CustomerDataInputs: FC = () => {
    const { t } = useTranslation();
    const countries = useCountries();
    const form = useFormContext();

    const { data: locations } = useLocationList({});

    const {
        fields: contactDetails,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: 'contactDetails',
    });

    return (
        <>
            <Section title={t('data')}>
                <FormGrid xs={12} md="auto">
                    <ControlledInput name="name" label={t('name')} required />
                    <ControlledInput name="companyName" label={t('companyName')} />
                    <ControlledInput name="accountNumber" label={t('accountNumber')} />
                    <ControlledInput name="taxNumber" label={t('taxNumber')} />
                </FormGrid>
            </Section>

            <Section title={t('comment')}>
                <Box pl={2}>
                    <ControlledInput name="comment" required multiline rows={4} sx={{ minWidth: 825 }} />
                </Box>
            </Section>

            <Section title={t('address')}>
                <FormGrid xs={12} md="auto">
                    <ControlledInput name="address.street" label={t('street')} sx={{ minWidth: 615 }} required />
                    <ControlledInput name="address.number" label={t('number')} sx={{ minWidth: 150 }} required />
                </FormGrid>
                <FormGrid xs={12} md="auto">
                    <ControlledInput
                        name="address.postalCode"
                        label={t('postalCode')}
                        sx={{ minWidth: 150 }}
                        required
                    />
                    <ControlledInput name="address.city" label={t('city')} required />
                    <ControlledAutocomplete
                        name="address.country"
                        label={t('country')}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={countries}
                        required
                    />
                </FormGrid>
            </Section>

            <Section title={t('contactDetails')}>
                <Stack alignItems="center" spacing={2} direction="column">
                    {contactDetails.map((contact, index) => (
                        <Fragment key={contact.id}>
                            <ContactDataInputs index={index} remove={remove} />
                        </Fragment>
                    ))}
                </Stack>
                <Button
                    onClick={() => append({ type: ContactType.Email, data: '' })}
                    color="secondary"
                    sx={{ span: { mb: 0.5, mx: 1 } }}
                >
                    <span>+</span> {t('contactDetailsAdd')}
                </Button>
            </Section>

            <Section title={t('locationInvites')}>
                <Stack>
                    {(locations?.data || [])?.map((location, index) => (
                        <Stack key={index}>
                            <ControlledCheckbox
                                name={`invites.[${index}].wantsInvite`}
                                label={
                                    <Trans
                                        i18nKey="wantsInvite"
                                        components={[<b />]}
                                        values={{ location: location.name }}
                                    />
                                }
                            />
                        </Stack>
                    ))}
                </Stack>
            </Section>
        </>
    );
};
