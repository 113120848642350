import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as countries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesNL from 'i18n-iso-countries/langs/nl.json';
import React, { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter } from 'react-router-dom';
import { CarityTheme, Main, Statusbar } from './main';
import './translations';

countries.registerLocale(countriesEN);
countries.registerLocale(countriesNL);

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const App: FC = () => {
    return (
        <BrowserRouter>
            <CarityTheme>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Main />
                        <Statusbar />
                    </LocalizationProvider>
                </QueryClientProvider>
            </CarityTheme>
        </BrowserRouter>
    );
};
