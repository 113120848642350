import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    InUseWarning,
    ISupplier,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useConsignmentsList, useDeleteConsignment } from '../../hooks';
import { Button, IconButton } from '@mui/material';
import { Print } from '@mui/icons-material';
import { SaveConsignmentDialog } from '../../components';
import { AxiosError } from 'axios';

export const ConsignmentsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CONSIGNMENTS_WRITE);
    const [showConsignmentModal, setShowConsignmentModal] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'consignmentNumber', sort: 'asc' as GridSortDirection }],
    });

    const { mutateAsync: deleteConsignment } = useDeleteConsignment();
    const { data: consignments, isPending } = useConsignmentsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteConsignment(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteConsignment],
    );

    const columns: GridColDef[] = [
        { field: 'consignmentNumber', headerName: t('consignmentNumber'), minWidth: 50, flex: 0.5 },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 100,
            flex: 1,
            valueFormatter: (value: ISupplier) => value.name,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            window.open(`/dvc-auction-api/consignments/${id}/receipt`, '__blank');
                        }}
                    >
                        <Print />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/article/consignments/${id}`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('consignmentDeleteWarningTitle')}
                            deleteWarningText={t('consignmentDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('consignments')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button color="primary" variant="contained" onClick={() => setShowConsignmentModal(true)}>
                        {t('newConsignment')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={consignments?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={consignments?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/article/consignments/${row.id}`)}
            />

            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('consignmentHasArticles')}
            />

            <SaveConsignmentDialog open={showConsignmentModal} close={() => setShowConsignmentModal(false)} />
        </Page>
    );
};
