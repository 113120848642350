import { IAddress } from '../models';

export const addressFormatter = (address: IAddress): string => {
    const array = [
        `${address.street}${!address.number && address.street ? ',' : ''}`,
        `${address.number}${address.number ? ',' : ''}`,
        address.postalCode,
        address.city,
        address.country,
    ].filter((val) => !!val);
    return array.join(' ');
};
