import { ILot, ILotForm, ISaveLot } from '../../shared';

export const lotToFormMapper = (item: ILot): ILotForm => {
    return {
        ...item,
        articles: item.articles.map((article) => ({ article })),
        lotNumberSuffix: item.lotNumberSuffix || '',
        buyerNumber: item.buyerNumber || '',
        hammerPrice: item.hammerPrice || '',
    };
};

export const lotFromFormMapper = (item: ILotForm, auctionId: string): ISaveLot => {
    return {
        ...item,
        articleIds: item.articles?.map(({ article }) => article?.id as string),
        auctionId,
        lotNumberSuffix: item.lotNumberSuffix || null,
        buyerNumber: item.buyerNumber ? Number(item.buyerNumber) : null,
        hammerPrice: item.hammerPrice ? Number(item.hammerPrice) : null,
    };
};
