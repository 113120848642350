import { Autocomplete, createFilterOptions, FilterOptionsState, TextField } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCategoriesList } from '../../../admin/hooks';
import { ICategory } from '../../../shared';
import { CategoryDialog } from '../../../admin/components';

export const SelectAutocompleteCategories: FC = () => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();

    const { data: categories } = useCategoriesList({ pageSize: 200 });
    const [showAddCategory, setShowAddCategory] = useState<{ show: boolean; value: string }>({
        show: false,
        value: '',
    });

    const filterOptions = useCallback(
        (options: (ICategory & { label: string })[], params: FilterOptionsState<ICategory>) => {
            const filtered = createFilterOptions<ICategory & { label: string }>()(options, params);
            const uniqueInput = !categories?.data.find(
                (category) => category.descriptionNl.toLocaleLowerCase() === params.inputValue.toLocaleLowerCase(),
            );

            if (params.inputValue !== '' && uniqueInput) {
                filtered.push({
                    id: '',
                    code: '',
                    descriptionNl: params.inputValue,
                    descriptionEn: '',
                    label: t('addNewValue', { value: params.inputValue }),
                });
            }
            return filtered;
        },
        [categories?.data, t],
    );

    const onClose = useCallback(
        (value: ICategory | undefined) => {
            setShowAddCategory({ show: false, value: '' });

            if (value) {
                watch('categories').push({ ...value, label: value.descriptionNl });
                setValue('categories', watch('categories'));
            }
        },
        [setValue, watch],
    );

    return (
        <>
            <Controller
                name="categories"
                defaultValue={[]}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, data, reason, details) => {
                            reason !== 'removeOption' && !details?.option?.id
                                ? setShowAddCategory({ show: true, value: details?.option?.descriptionNl })
                                : onChange(data);
                        }}
                        value={value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('categories')}
                                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        options={categories?.data.map((cat) => ({ ...cat, label: cat.descriptionNl })) || []}
                        freeSolo
                        multiple
                        filterOptions={(options, params) => filterOptions(options, params)}
                        autoHighlight
                        clearOnBlur
                    />
                )}
            />
            <CategoryDialog open={showAddCategory} close={onClose} />
        </>
    );
};
