import { auctionApi, BaseClient, ILot, ISaveLot, ISaveLotStatus, ISaveSignature } from '../../shared';

class LotClient extends BaseClient<ILot, ISaveLot> {
    constructor() {
        super(auctionApi, '/lots');
    }

    public async setPaidStatus(saveLotStatus: ISaveLotStatus): Promise<void> {
        await auctionApi.post('/lots/paid', saveLotStatus);
    }

    public async saveSignature(signature: ISaveSignature): Promise<void> {
        await auctionApi.post(`/lots/${signature.lotId}/signature`, signature);
    }
}

export const lotClient = new LotClient();
