import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, date, lazy, mixed, number, object, string } from 'yup';
import { IArtist, ICategory, ISupplier } from '../../shared';
import { ConsignmentType } from '../enums';

export function useArticleSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                consignment: object().shape({
                    id: string().optional(),
                    consignmentNumberPart1: string().required(),
                    consignmentNumberPart2: string().required(),
                    date: date()
                        .typeError(t('invalidDate'))
                        .nullable()
                        .when('consignment.id', { is: (val: string) => !val, then: (schema) => schema.required() }),
                    supplier: mixed<ISupplier>()
                        .nullable()
                        .when('consignment.id', { is: (val: string) => !val, then: (schema) => schema.required() }),
                }),
                articleNumber: number().min(0).required(t('required')).typeError(t('invalidNumber')),
                consignmentType: mixed<ConsignmentType>()
                    .oneOf(Object.values(ConsignmentType), t('required'))
                    .required(),
                shortDescription: string().optional(),
                descriptionNl: string().required(t('required')),
                descriptionEn: string().required(t('required')),
                commission: lazy((value) => {
                    return value === ''
                        ? string().required(t('required'))
                        : number().min(0).required(t('required')).typeError(t('invalidNumber'));
                }),
                minSalePrice: lazy((value) => {
                    return value === '' ? string() : number().min(0).typeError(t('invalidNumber')).nullable();
                }),
                minEstimate: lazy((value) => {
                    return value === '' ? string() : number().min(0).typeError(t('invalidNumber')).nullable();
                }),
                maxEstimate: lazy((value) => {
                    return value === '' ? string() : number().min(0).typeError(t('invalidNumber')).nullable();
                }),
                estimateComment: string().optional(),
                categories: array().of(mixed<ICategory>().required()).optional(),
                artist: mixed<IArtist>()
                    .transform((value) => (value ? value : null))
                    .optional()
                    .nullable(),

                dimensions: object()
                    .shape({
                        widthInCm: number()
                            .transform((value) => {
                                if (value === '' || isNaN(value)) {
                                    return null;
                                }
                                return value;
                            })
                            .nullable(),
                        heightInCm: number()
                            .transform((value) => {
                                if (value === '' || isNaN(value)) {
                                    return null;
                                }
                                return value;
                            })
                            .nullable(),
                        depthInCm: number()
                            .transform((value) => {
                                if (value === '' || isNaN(value)) {
                                    return null;
                                }
                                return value;
                            })
                            .nullable(),
                        weight: number()
                            .transform((value) => {
                                if (value === '' || isNaN(value)) {
                                    return null;
                                }
                                return value;
                            })
                            .nullable(),
                        weightUnit: string().optional(),
                    })
                    .optional(),
                paidToSupplier: boolean().required(),
            }),
        [t],
    );
}
