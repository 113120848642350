import { AddCircle, Article, Print } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    InUseWarning,
    IParams,
    ISupplier,
    PermissionKeys,
    RowActions,
    SearchField,
    TableComponent,
    useHasPermission,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useConsignmentsList, useDeleteConsignment } from '../../hooks';
import { ReturnArticles } from '../return-articles/return-articles.component';
import { SaveConsignmentDialog } from '../save-consignment-dialog/save-consignment-dialog.component';

interface Props {
    supplier: ISupplier;
}

export const SupplierDataConsignments: FC<Props> = ({ supplier }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { hasPermission } = useHasPermission();

    const navigate = useNavigate();
    const hasWritePermission = hasPermission(PermissionKeys.CONSIGNMENTS_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const [showConsignmentModal, setShowConsignmentModal] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { mutateAsync: deleteConsignment } = useDeleteConsignment();
    const { data: consignments, isPending } = useConsignmentsList({
        page: page,
        pageSize: pageSize,
        sortBy: 'date',
        sortOrder: 'DESC',
        search: debouncedSearch,
        supplierId: id,
    });

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteConsignment(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteConsignment],
    );

    const columns: GridColDef[] = [
        { field: 'consignmentNumber', headerName: t('consignmentNumber'), minWidth: 50, flex: 0.5, sortable: false },
        {
            field: ' ',
            headerName: '',
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            window.open(`/dvc-auction-api/consignments/${id}/receipt`, '__blank');
                        }}
                    >
                        <Print />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/article/consignments/${id}`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('consignmentDeleteWarningTitle')}
                            deleteWarningText={t('consignmentDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];
    return (
        <Widget
            title={t('consignments')}
            hasTable
            icon={<Article />}
            actions={
                <Stack direction="row" spacing={1}>
                    <ReturnArticles supplierId={id} />
                    <SearchField search={search} onSearch={setSearch} />
                    <IconButton onClick={() => setShowConsignmentModal(true)}>
                        <AddCircle color="primary" />
                    </IconButton>
                </Stack>
            }
        >
            <TableComponent
                rows={consignments?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={consignments?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/article/consignments/${row.id}`)}
            />

            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('consignmentHasArticles')}
            />

            <SaveConsignmentDialog
                open={showConsignmentModal}
                close={() => setShowConsignmentModal(false)}
                supplier={supplier}
            />
        </Widget>
    );
};
