import { ICustomer, ICustomerForm, ILocation, IOrigins, ISaveCustomer } from '../../shared';

export const customerToFormMapper = (item: ICustomer, countries: IOrigins[], locations: ILocation[]): ICustomerForm => {
    return {
        ...item,
        address: {
            ...item.address,
            country: countries.find((country) => country.label === item.address.country) as IOrigins,
        },
        invites: locations?.map((location) => ({
            location: location.name,
            wantsInvite: item.invites?.find((invite) => invite.location === location.name)?.wantsInvite || false,
        })),
    };
};

export const customerFromFormMapper = (item: ICustomerForm): ISaveCustomer => {
    return {
        ...item,
        address: { ...item.address, country: item.address.country.label },
    };
};
