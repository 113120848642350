import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ILotForm, LotStatus, Page } from '../../../shared';
import { ActiveSlotAction, ActiveSlotData, ActiveSlotNavigation, PrintDialog } from '../../components';
import { useGetAuctionLots, useSaveLot } from '../../hooks';
import { lotFromFormMapper, lotToFormMapper } from '../../mappers';
import { useLotSchema } from '../../validators';

type Params = {
    id: string;
    first: string;
    last: string;
};

export const ActiveAuctionSlotPage: FC = () => {
    const { id, first, last } = useParams<Params>() as Params;
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const current = params.get('current');
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [lastLot, setLastLot] = useState(false);

    const { data: lots } = useGetAuctionLots(id, { firstLotNumber: Number(first), lastLotNumber: Number(last) });
    const { mutateAsync: saveLot, isPending } = useSaveLot();

    const form = useForm<ILotForm>({ resolver: yupResolver(useLotSchema()), mode: 'onSubmit' });

    const currentLot = useMemo(() => {
        if (current) {
            return lots?.find(({ lotNumber, lotNumberSuffix }) => `${lotNumber}${lotNumberSuffix || ''}` === current);
        }
        return lots?.find((lot) => lot.status === LotStatus.AWAITING_AUCTION) || lots?.[0];
    }, [lots, current]);

    const currentLotIndex = useMemo(() => lots?.findIndex((l) => l.id === currentLot?.id), [lots, currentLot]);

    useEffect(() => {
        if (currentLot) {
            form.reset(lotToFormMapper(currentLot));
        }

        if (lots?.length) {
            setLastLot(currentLotIndex === lots.length - 1);
        }
    }, [currentLot, currentLotIndex, form, lots?.length]);

    const onClosePrintDialog = (print: boolean) => {
        form.handleSubmit((item) => onSubmit(item, print))();
    };

    const onNext = () => {
        if (lots && currentLotIndex !== undefined) {
            const lot = lots[currentLotIndex + 1];
            lastLot
                ? navigate(`/auction/auctions/${id}/detail`)
                : navigate(`?current=${lot?.lotNumber}${lot?.lotNumberSuffix || ''}`);
        }
    };

    const onSubmit = async (item: ILotForm, print?: boolean) => {
        if (!showPrintDialog) return setShowPrintDialog(true);
        if (currentLot) {
            saveLot({ id: currentLot.id, item: { ...lotFromFormMapper(item, id), print } });
            setShowPrintDialog(false);
            onNext();
        }
    };

    return (
        <Page>
            <FormProvider {...form}>
                {currentLot && (
                    <>
                        <form noValidate={true} autoComplete="off">
                            <ActiveSlotNavigation lots={lots} auctionId={id} currentLot={currentLot} onNext={onNext} />
                            <Grid container alignItems="stretch" spacing={2} sx={{ height: '330px' }}>
                                <ActiveSlotAction
                                    status={LotStatus.SOLD}
                                    save={form.handleSubmit((item) => onSubmit(item))}
                                    isPending={isPending}
                                    lots={lots}
                                />
                                <ActiveSlotData currentLot={currentLot} />
                                <ActiveSlotAction
                                    status={LotStatus.NOT_SOLD}
                                    save={form.handleSubmit((item) => onSubmit(item))}
                                    isPending={isPending}
                                    lots={lots}
                                />
                            </Grid>
                        </form>

                        <PrintDialog open={showPrintDialog} onClose={onClosePrintDialog} lastLot={lastLot} />
                    </>
                )}
            </FormProvider>
        </Page>
    );
};
