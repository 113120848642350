import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';
import { Loading } from '../loading/loading.component';
import { useBreakpoint } from '../../hooks';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    filter?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode | ReactNode[];
    onBack?: MouseEventHandler;
    loading?: boolean;
}

export const Page: FC<Props> = ({ actions, onBack, title, subTitle, filter, children, loading }) => {
    const mappedActions = useMemo(() => (Array.isArray(actions) ? actions : [actions]), [actions]);

    return (
        <Box sx={{ mb: useBreakpoint('sm') ? '0' : '75px' }}>
            {!title && (
                <Box sx={{ p: 3 }}>
                    <Box>{loading ? <Loading /> : children}</Box>
                </Box>
            )}

            {title && (
                <Box>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="nowrap"
                        sx={{
                            pl: 3,
                            pr: 3,
                            backgroundColor: 'white',
                            height: 96,
                            '.MuiFormControl-root': { mb: 0 },
                        }}
                    >
                        <Grid item>
                            <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
                                {onBack && (
                                    <Grid item>
                                        <IconButton
                                            color="inherit"
                                            aria-label="back button"
                                            onClick={onBack}
                                            size="large"
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant="subtitle1" mt={2} color="text.secondary">
                                        {title}
                                    </Typography>

                                    {subTitle && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {subTitle}
                                        </Typography>
                                    )}
                                </Grid>
                                {filter && <Grid item>{filter}</Grid>}
                            </Grid>
                        </Grid>
                        {mappedActions && (
                            <Grid item>
                                <Grid container flexWrap="nowrap" alignItems="center" spacing={1}>
                                    {mappedActions.map((action, index) => (
                                        <Grid item key={index}>
                                            {action}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Divider />
                    <Box sx={{ p: 3 }}>
                        <Box>{loading ? <Loading /> : children}</Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
