import { MenuItem, Stack, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    formatDateString,
    FormGrid,
    IParams,
    LabelValue,
    Section,
    useDebouncedState,
} from '../../../shared';
import { ConsignmentType } from '../../enums';
import { useConsignmentByNumber } from '../../hooks';
import { consignmentToFormMapper } from '../../mappers';
import { SelectAutocompleteSupplier } from '../save-consignment-dialog/select-autocomplete-supplier.component';

export const ArticleGeneralInputs: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { watch, setValue } = useFormContext();
    const [debouncedConsignmentNumber, , setConsignmentNumber] = useDebouncedState('');

    const supplierName = watch('consignment.supplier.name');
    const consignment = watch('consignment');
    useEffect(() => {
        if (consignment?.consignmentNumberPart1 && consignment?.consignmentNumberPart2) {
            setConsignmentNumber(`${consignment?.consignmentNumberPart1}/${consignment?.consignmentNumberPart2}`);
        }
    }, [consignment?.consignmentNumberPart1, consignment?.consignmentNumberPart2, setConsignmentNumber]);

    const { data } = useConsignmentByNumber(debouncedConsignmentNumber);
    useEffect(() => {
        if (!id) {
            if (data) {
                setValue('consignment', consignmentToFormMapper(data));
                setValue('consignment.supplier', data.supplier);
            } else {
                setValue('consignment.id', '');
                setValue('consignment.supplier', null);
                setValue('consignment.date', '');
            }
        }
    }, [id, setValue, data]);

    return (
        <Section title={t('general')}>
            <Stack spacing={2} sx={{ 'span.MuiTypography-root': { color: 'grey' } }}>
                {id ? (
                    <>
                        <LabelValue label={t('consignment')} value={consignment?.consignmentNumber} sx={{ pl: 2 }} />
                        <LabelValue label={t('date')} value={formatDateString(consignment?.date)} sx={{ pl: 2 }} />
                        <LabelValue label={t('supplier')} value={supplierName} sx={{ pl: 2 }} />
                    </>
                ) : (
                    <FormGrid xs={12}>
                        <Stack direction="row" alignItems="center">
                            <ControlledInput
                                name="consignment.consignmentNumberPart1"
                                label={t('consignmentNumber')}
                                required
                                sx={{ minWidth: 100 }}
                            />
                            <Typography sx={{ p: 2 }}>/</Typography>
                            <ControlledInput
                                name="consignment.consignmentNumberPart2"
                                required
                                sx={{ minWidth: 100 }}
                            />
                        </Stack>
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-evenly">
                            <ControlledDatePicker
                                name="consignment.date"
                                label={t('date')}
                                disabled={!!consignment?.id}
                            />
                        </Stack>
                        <SelectAutocompleteSupplier name="consignment.supplier" disabled={!!consignment?.id} />
                    </FormGrid>
                )}

                <FormGrid xs={12} lg={6}>
                    <ControlledNumberInput name="articleNumber" label={t('articleNumber')} required decimalScale={0} />
                    <ControlledSelect name="consignmentType" label={t('consignmentType')} required>
                        {Object.values(ConsignmentType).map((type) => (
                            <MenuItem value={type} key={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                    <ControlledNumberInput
                        name="commission"
                        label={t('commission')}
                        required
                        InputProps={{ endAdornment: '%' }}
                    />
                    <ControlledNumberInput
                        name="minSalePrice"
                        label={t('minSalePrice')}
                        InputProps={{ startAdornment: '€' }}
                        sx={{ input: { pl: 1 } }}
                    />
                    <ControlledCheckbox
                        name="paidToSupplier"
                        label={<Typography sx={{ pt: 0.5 }}>{t('paidToSupplier')} </Typography>}
                    />
                </FormGrid>
            </Stack>
        </Section>
    );
};
