import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mixed, number, object } from 'yup';
import { ICustomer } from '../../shared';

export function useBuyerSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                buyerNumber: number().min(0).typeError(t('invalidNumber')).required(),
                customer: mixed<ICustomer>().required(),
            }),
        [t],
    );
}
