import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, lazy, mixed, number, object, string } from 'yup';
import { IArticle, IPlatform, LotStatus } from '../../shared';

export function useLotSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                lotNumber: number().min(0).typeError(t('invalidNumber')).required(),
                lotNumberSuffix: string()
                    .nullable()
                    .test('lengthCheck', t('maxSuffixLength'), (value) => {
                        if (value == undefined) {
                            return true;
                        }
                        return (value?.length || 0) <= 1;
                    })
                    .matches(/^([^0-9]*)$/, t('invalidLotNumberSuffix')),
                minEstimate: number()
                    .min(0)
                    .typeError(t('invalidNumber'))
                    .transform((value) => {
                        if (value === '' || isNaN(value)) {
                            return null;
                        }
                        return value;
                    })
                    .nullable(),
                maxEstimate: number()
                    .min(0)
                    .typeError(t('invalidNumber'))
                    .transform((value) => {
                        if (value === '' || isNaN(value)) {
                            return null;
                        }
                        return value;
                    })
                    .nullable(),
                descriptionNl: string().required(),
                descriptionEn: string().required(),
                articles: array()
                    .of(
                        object()
                            .shape({
                                article: mixed<IArticle>()
                                    .required()
                                    .nullable()
                                    .test('required', t('required'), (val) => !!val),
                            })
                            .required(),
                    )
                    .min(1, t('noArticleSelected'))
                    .required(),
                buyerNumber: lazy((value) => {
                    return value === ''
                        ? string().when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                        : number()
                              .nullable()
                              .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() });
                }),
                hammerPrice: lazy((value) => {
                    return value === ''
                        ? string().when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                        : number()
                              .nullable()
                              .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() });
                }),
                status: mixed<LotStatus>().optional(),
                platform: mixed<IPlatform>()
                    .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                    .nullable()
                    .optional(),
            }),
        [t],
    );
}
