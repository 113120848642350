import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { transparentize } from 'polished';
import { IEnvironment } from '../../shared';

export const createBaseTheme = (themeOptions: IEnvironment['theme'], palette = getPalette(themeOptions)): Theme => {
    const theme = createTheme({ palette });

    const typography = getTypography(theme);
    const components = getComponents(theme);

    return createTheme(theme, {
        typography,
        components,
    });
};

function getComponents({ palette: { grey, primary }, breakpoints, ...theme }: Theme): ThemeOptions['components'] {
    return {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${grey['300']}`,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${grey['300']}`,
                    backgroundColor: 'white',
                    color: primary.main,
                    background: '#FFF',
                    zIndex: theme.zIndex.drawer + 1,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [breakpoints.up('xs')]: {
                        height: 55,
                        minHeight: 55,
                    },
                    ['& .app-logo']: {
                        display: 'flex',
                        marginRight: 12,

                        ['& img']: {
                            width: 40,
                        },
                    },
                    '& .MuiTab-root': {
                        minHeight: 55,
                    },
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
                fullWidth: true,
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    background: '#FAFAFA',
                    justifyContent: 'flex-start',
                    padding: '16px 24px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 18,
                    fontWeight: 'bolder',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
    };
}

function getTypography({ palette }: Theme): ThemeOptions['typography'] {
    return {
        appTitle: {
            width: '221px',
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 'bold',
            color: palette.text.secondary,
            paddingRight: 24,
            borderRight: `solid 1px ${palette.grey['300']}`,
            height: 55,
        },
        subtitle1: {
            fontSize: 18,
            marginBottom: 18,
            fontWeight: 'bold',
        },
        subtitle2: {
            fontSize: 14,
            marginBottom: 14,
            fontWeight: 'bold',
        },
        caption: {
            fontSize: 12,
            marginBottom: 6,
        },
    };
}

function getPalette(themeOptions: IEnvironment['theme']): ThemeOptions['palette'] {
    return {
        primary: {
            main: themeOptions.color,
            shade: transparentize(0.85, themeOptions.color),
        },
        secondary: {
            main: themeOptions.color,
            shade: transparentize(0.85, themeOptions.color),
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
        },
    };
}
