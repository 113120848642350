import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledAutocomplete, useDebouncedState } from '../../../shared';
import { useCustomersList } from '../../hooks';

export const SelectCustomer: FC = () => {
    const { t } = useTranslation();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: customers, isPending } = useCustomersList({ search: debouncedSearch });

    return (
        <ControlledAutocomplete
            name="customer"
            label={t('customer')}
            defaultValue={null}
            options={debouncedSearch ? customers?.data || [] : []}
            getOptionLabel={(customer) => `${customer.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(e, value) => setSearch(value)}
            noOptionsText={debouncedSearch ? t('noCustomerFound') : t('startSearchingCustomers')}
            loading={isPending}
            required
        />
    );
};
