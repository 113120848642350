import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowDrawer } from '../../../shared';
import { useNavigate } from 'react-router-dom';

export const AppTitle: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { showDrawer, setShowDrawer, hasDrawer } = useShowDrawer();
    const handleDrawerToggle = () => {
        setShowDrawer(!showDrawer);
    };

    return (
        <Typography variant="appTitle" sx={{ cursor: 'pointer' }}>
            <Box className="app-logo" sx={{ display: { xs: 'none', xl: 'block' } }} onClick={() => navigate('')}>
                <img src={window.auction.environment.theme.logo} alt={t('nav.title')} />
            </Box>
            <Box onClick={() => navigate('')}>{t('nav.title')}</Box>
            {hasDrawer && (
                <IconButton
                    color="inherit"
                    aria-label="open side navigation"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ ml: 0.5, mr: 2, display: { xl: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
            )}
        </Typography>
    );
};
