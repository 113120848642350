import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IConsignment, IConsignmentQuery, QueryKeys, useDelete, useList, UseListParams, useSave } from '../../shared';
import { consignmentClient } from '../clients';

const client = consignmentClient;
const listKey = QueryKeys.Consignments;
const itemKey = QueryKeys.Consignment;

export function useConsignmentsList(...args: UseListParams<IConsignment, IConsignmentQuery>) {
    return useList(client, listKey, ...args);
}

export function useConsignment(id: string, includeReturns: boolean) {
    return useQuery<IConsignment | null, AxiosError>({
        queryKey: [QueryKeys.Consignment, id, includeReturns],
        queryFn: () => client.getConsignment(id, includeReturns),
        enabled: !!id,
    });
}

export function useConsignmentByNumber(consignmentNumber: string) {
    return useQuery<IConsignment | null, AxiosError>({
        queryKey: [QueryKeys.Consignment, consignmentNumber],
        queryFn: () => client.getConsignmentByNumber(consignmentNumber),
        enabled: !!consignmentNumber,
    });
}

export function useSaveConsignment() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteConsignment() {
    return useDelete(client, listKey);
}

export function useGetNextArticleNumber(id: string) {
    return useQuery<number, AxiosError>({
        queryKey: [QueryKeys.NextArticleNumber, id],
        queryFn: () => client.getNextArticleNumber(id),
        enabled: !!id,
    });
}
