import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useArticlesList, useDeleteArticle } from '../../hooks';
import { Button, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

export const ArticlesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.ARTICLES_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'articleNumber', sort: 'asc' as GridSortDirection }],
    });

    const { mutateAsync: deleteArticle } = useDeleteArticle();
    const { data: articles, isPending } = useArticlesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        {
            field: 'articleNumber',
            headerName: t('articleNumber'),
            minWidth: 150,
            flex: 0.25,
            valueFormatter: (value, row) => `${row.consignment?.consignmentNumber}/${row.articleNumber}`,
        },
        {
            field: 'paidToDVC',
            headerName: t('paidStatusDVC'),
            minWidth: 140,
            sortable: false,
            valueFormatter: (value: boolean) => (value ? t('paidToDVC') : t('notPaidToDVC')),
        },
        {
            field: 'paidToSupplier',
            headerName: t('paidStatusSupplier'),
            minWidth: 185,
            sortable: false,
            valueFormatter: (value: boolean) => (value ? t('paidToSupplier') : t('notPaidToSupplier')),
        },
        { field: 'descriptionNl', headerName: t('descriptionNl'), sortable: false, minWidth: 200, flex: 1 },
        {
            field: 'supplier',
            headerName: t('supplier'),
            sortable: false,
            minWidth: 200,
            flex: 1,
            valueFormatter: (value, row) => `${row.consignment?.supplier?.name}`,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${id}/detail`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/article/articles/${id}/edit`)}
                            onDelete={() => deleteArticle(id)}
                            deleteWarningTitle={t('articleDeleteWarningTitle')}
                            deleteWarningText={t('articleDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('articles')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button component={Link} to="/article/articles/new" color="primary" variant="contained">
                        {t('newArticle')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={articles?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={articles?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/article/articles/${row.id}/detail`)}
            />
        </Page>
    );
};
